import { Outlet } from 'react-router-dom'
import config from '../Config'
import Box from './Box'
import SandboxLoggedInPage from './SandboxLoggedInPage'

const AppContainer = () => {
  if (config.enableLoggedInSandbox) return <SandboxLoggedInPage />

  return (
    <Box fullWidth fullHeight>
      <Outlet />
    </Box>
  )
}

export default AppContainer
