import { bigSpacing } from '../enums/Spacings'
import Box from './Box'
import { Layout } from './Layout'
import Loader from './Loader'
import MenuVitecNext from './MenuVitecNext'

export function LoadingPage() {
  return (
    <Box fullWidth fullPadding spacing={bigSpacing} align="center">
      <Loader />
    </Box>
  )
}

export function LayoutLoadingPage() {
  return (
    <Layout enableFooter={false} MenuComponent={MenuVitecNext} enableRightBottomImage={false}>
      <LoadingPage />
    </Layout>
  )
}
