import * as Auth from 'firebase/auth'
import { onAuthStateChanged } from 'firebase/auth'
import invariant from 'invariant'
import React, { useEffect } from 'react'
import eventEmitter, { Events } from '../../../../pure/libs/EventEmitter'
import { SignInState, SignInSteps, SignInViewProps } from '../../../../pure/types/SignInTypes'
import useAppState, { State } from '../hooks/useAppState'
import useIsMobile from '../hooks/useIsMobile'
import useSignInState from '../hooks/useSignInState'
import useSignInUserWithVitecNextOrder from '../hooks/useSignInUserWithVitecNextOrder'
import { auth } from '../libs/Firebase'
import { signInWithPopup } from '../libs/FirebaseHelper'
import { DEFAULT_SIGN_IN_STATE, SignInPageProps } from '../libs/SignInHelper'
import * as SignInMachineHelper from '../libs/SignInMachine'
import { SignInMachineServices } from '../libs/SignInMachineHelper'
import { AppleAuthProvider, GoogleAuthProvider, MicrosoftAuthProvider } from '../libs/SignInProviders'
import onUnhandledPromiseRejection from '../libs/onUnhandledPromiseRejection'
import AppContainer from './AppContainer'
import { LayoutLoadingPage } from './LoadingPage'
import SignInCode from './SignInCode'
import SignInLanding from './SignInLanding'

const SignInViewsDesktop = {
  [SignInSteps.LANDING]: SignInLanding,
  [SignInSteps.SIGN_UP]: SignInLanding,
  [SignInSteps.ENTER_CODE]: SignInCode
}

const SignInPage: React.FC<SignInPageProps> = (props) => {
  const isMobile = useIsMobile()
  const Views = SignInViewsDesktop
  const { state, setState } = useAppState()
  const [isLoading, setIsLoading] = React.useState(true)
  const { signInState, setSignInState } = useSignInState(props.signInState)

  useSignInUserWithVitecNextOrder({
    onFinally: () => {
      setSignInState(DEFAULT_SIGN_IN_STATE)
      setIsLoading(false)
    }
  })

  useEffect(() => {
    onAuthStateChanged(auth, (firebaseUser) => {
      if (!firebaseUser) return

      setIsLoading(false)
      //@ts-ignore We do this to get the latest redirectUrl
      setState((state: State) => {
        return { ...state, firebaseUser }
      })
    })
  }, [])

  if (isLoading) return <LayoutLoadingPage />

  if (!!state.firebaseUser) return <AppContainer />

  const onError = (err) => {
    onUnhandledPromiseRejection(err)
    setSignInState(DEFAULT_SIGN_IN_STATE)
    eventEmitter.emit(Events.NEW_SERVER_ERROR)
  }

  const signInWithAuthProvider = (authProvider: Auth.AuthProvider) =>
    Promise.resolve(setIsLoading(true))
      .then((): unknown => signInWithPopup(authProvider))
      .catch((err) => {
        onError(err)
        setIsLoading(false)
      })

  const signInViewProps: SignInViewProps = {
    isLoading,
    signInState: signInState,
    onClickBack: () => {
      switch (signInState.step) {
        default:
          return setSignInState(DEFAULT_SIGN_IN_STATE)
      }
    },
    onTakeMeBack: () => setSignInState(DEFAULT_SIGN_IN_STATE),
    onLoginWithGoogle: () => signInWithAuthProvider(GoogleAuthProvider).catch(onError),
    onLoginWithMicrosoft: () => signInWithAuthProvider(MicrosoftAuthProvider).catch(onError),
    onLoginWithApple: () => signInWithAuthProvider(AppleAuthProvider).catch(onError),
    onPressContinue: (signInState) =>
      Promise.resolve(setIsLoading(true))
        .then(() => SignInMachineHelper.onPressContinue(signInState, { isMobile }, state, SignInMachineServices))
        .then((signInState: SignInState) => setSignInState(signInState))
        .catch(onError)
        .finally(() => setIsLoading(false))
  }

  const component: React.FC<SignInViewProps> = Views[signInViewProps.signInState.step]
  invariant(component, `Cant find Onboarding Step for %s`, signInViewProps.signInState.step)

  return React.createElement(component, signInViewProps)
}

export default SignInPage
