import { collection, limit, limitToLast, orderBy, query, where } from 'firebase/firestore'
import { FeatureFlagEnvironment, FeatureFlags } from '../../../../pure/enums/FeatureFlags.js'
import Collections from '../../../../pure/libs/Collections.js'
import { createUniqueId } from '../../../../pure/libs/Common.js'
import { getPathToUploadedFiles } from '../../../../pure/libs/UploadPathHelper.js'
import { Upload, UploadedFile } from '../../../../pure/types/JaguarTypes.js'
import { Organization, User } from '../../../../pure/types/types.js'
import { VitecNextEstate, VitecNextOrder } from '../../../../pure/types/VitecNODerivedTypes.js'
import config from '../Config.js'
import { getObjectQuery, getObjectsQuery, getQuery } from '../libs/FirebaseStorageClientForWebApp.js'
import { useFirestoreQuery } from './useFirestoreQuery.js'
import { db } from '../libs/Firebase.js'

// use useUser instead
export const _useUser = (id: string) =>
  useFirestoreQuery<User>(getObjectQuery(id, Collections.USERS), { fnName: 'useUser', enabled: !!id })

// Single Objects
export const useOrganization = (organizationId = '') =>
  useFirestoreQuery<Organization>(getObjectQuery(organizationId as string, Collections.ORGANIZATIONS), {
    fnName: 'useOrganization',
    enabled: !!organizationId
  })

export const useUploads = (uid = '') =>
  useFirestoreQuery<Upload[]>(getQuery(Collections.UPLOADS, [where('metaData.userId', '==', uid)]), {
    fnName: 'useUploads',
    enabled: !!uid
  })

export const _useUploadedFiles = (id: string = '') =>
  useFirestoreQuery<UploadedFile[]>(getObjectsQuery(getPathToUploadedFiles(id)), {
    fnName: 'useUploadedFiles',
    enabled: !!id
  })

export const useUploadedFile = (fileId: string, upload?: Upload) =>
  useFirestoreQuery<UploadedFile>(
    getObjectQuery(fileId, getPathToUploadedFiles(upload?.id || createUniqueId()) as Collections),
    { fnName: 'useUploadedFile', enabled: !!fileId && !!upload?.id }
  )

export const useUpload = (id = '') =>
  useFirestoreQuery<Upload>(getObjectQuery(id as string, Collections.UPLOADS), {
    fnName: 'useUpload',
    enabled: !!id
  })

export const useVitecNOOrder = (id = '') =>
  useFirestoreQuery<VitecNextOrder>(getObjectQuery(id as string, Collections.VITEC_NO_ORDER), {
    fnName: 'useVitecNoOrder',
    enabled: !!id
  })

export const useVitecNextEstate = (id = '') =>
  useFirestoreQuery<VitecNextEstate>(getObjectQuery(id as string, Collections.VITEC_NEXT_ESTATES), {
    fnName: 'useVitecNoOrder',
    enabled: !!id
  })

export const useFeatureFlags = (id: FeatureFlagEnvironment = config.name) =>
  useFirestoreQuery<FeatureFlags>(getObjectQuery(id, Collections.FEATURE_FLAGS), {
    fnName: 'useFeatureFlags'
  })

export const useLastVitecNextOrder = ({ email }: { email?: string }) =>
  useFirestoreQuery<VitecNextOrder>(
    query(
      collection(db, Collections.VITEC_NO_ORDER),
      where('useremail', '==', email || ''),
      orderBy('orderPlaced'),
      limitToLast(1)
    ),
    {
      fnName: 'useLastVitecNextOrder',
      enabled: !!email
    }
  )
