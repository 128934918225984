import {
  IsVitecNextProductEnabledResponse,
  IsVitecNextProductEnabledResponseCode
} from '../../../../pure/leopard/IsVitecNextProductEnabledResponse'
import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'
import { toMB } from '../../../../pure/libs/Common'
import { VitecNextOrder } from '../../../../pure/types/VitecNODerivedTypes'
import { formatText, getFigmaText } from './TextRepository'
import { Texts } from './Texts'

export const IsVitecNextProductEnabledInfo: {
  [property in IsVitecNextProductEnabledResponseCode]: {
    getText: (args: {
      vitecNextOrder: VitecNextOrder
      isProductEnabled: IsVitecNextProductEnabledResponse
      vitecNextProduct: VitecNextProduct
    }) => string
  }
} = {
  DOCUMENT_NOT_FOUND: {
    getText: ({ vitecNextProduct }) => {
      if (vitecNextProduct === VitecNextProduct.IMAGE_CAPTIONS)
        return getFigmaText(Texts.ImageTextsNotificationItNoImgages)

      return getFigmaText(Texts.standardNotificationMissingDocument)
    }
  },
  DOCUMENT_TOO_BIG: {
    getText: ({ isProductEnabled }) =>
      formatText(getFigmaText(Texts.standardTooBigDocument), [toMB(isProductEnabled?.extra?.bytes || 0).toFixed(0)])
  },
  ADDRESS_NOT_FOUND: {
    getText: ({ vitecNextOrder }) =>
      formatText(getFigmaText(Texts.AreaDescriptionMissingArea), [vitecNextOrder?.address || ''])
  }
}
