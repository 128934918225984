import { BasicAssociationResponseItems } from '../leopard/JaguarTypesDerived'
import { VitecNextImportBooleanType } from '../types/VitecNextImportBooleanType'
import { VitecNextImportDoubleType } from '../types/VitecNextImportNumberType'
import { VitecNextImportTextType } from '../types/VitecNextImportTextType'
import { VitecNextImportType } from '../types/VitecNextImportType'

export type BasicAssociationResponseItemsKeysNotInJaguar = 'has_board_approval' | 'has_preemptive'
export type BasicAssociationResponseItemsKey =
  | keyof BasicAssociationResponseItems
  | BasicAssociationResponseItemsKeysNotInJaguar

export type BasicAssociationField = {
  key: BasicAssociationResponseItemsKey
  value: string
  checked?: boolean
  fulfilledAt?: string | null
  isFulfilling?: boolean
}

export type BasicAssociationFields = Record<BasicAssociationResponseItemsKey, BasicAssociationField>

export function replaceNewlineWithSpaces(str: string): string {
  return str.toString().replace(/\n\s+/g, '\n')
}

export const VitecNextOrderProductBasicAssociationItemsPure: {
  [property in BasicAssociationResponseItemsKey]: {
    vitecNextImportType?: VitecNextImportType
    vitecNextImportTextType?: VitecNextImportTextType
    vitecNextImportDoubleType?: VitecNextImportDoubleType
    vitecNextImportBooleanType?: VitecNextImportBooleanType
    format?: (value: string) => string
  }
} = {
  felleskostnader_pr_mnd: {
    vitecNextImportType: VitecNextImportType.RentPrMonth,
    vitecNextImportDoubleType: VitecNextImportDoubleType.RentPrMonth
  },
  borettslaget: {},
  borettslagets_org_nr: {},
  forretningsfører: {},
  styregodkjennelse: {
    vitecNextImportType: VitecNextImportType.BoardApproval,
    vitecNextImportTextType: VitecNextImportTextType.BoardApproval
  },
  forkjøpsrett: {
    vitecNextImportType: VitecNextImportType.Preemptive,
    vitecNextImportTextType: VitecNextImportTextType.Preemptive
  },
  husdyrhold: {
    vitecNextImportType: VitecNextImportType.Animals,
    vitecNextImportTextType: VitecNextImportTextType.Animals
  },
  lånebetingelser_fellesgjeld: {
    vitecNextImportType: VitecNextImportType.LoanTermsCollectiveDebt,
    vitecNextImportTextType: VitecNextImportTextType.LoanTermsCollectiveDebt,
    format: replaceNewlineWithSpaces
  },
  sikringsordning_fellesgjeld: {
    vitecNextImportType: VitecNextImportType.GuaranteeFund,
    vitecNextImportTextType: VitecNextImportTextType.GuaranteeFund,
    format: replaceNewlineWithSpaces
  },
  borettslagets_forsikringsselskap: {
    vitecNextImportType: VitecNextImportType.InsuranceCompany,
    vitecNextImportTextType: VitecNextImportTextType.InsuranceCompany
  },
  polisenummer_felles_forsikring: {
    vitecNextImportType: VitecNextImportType.InsurancePolicy,
    vitecNextImportTextType: VitecNextImportTextType.InsurancePolicy
  },
  eierskiftegebyr: {
    vitecNextImportType: VitecNextImportType.ChangeOfOwnershipFee,
    vitecNextImportDoubleType: VitecNextImportDoubleType.ChangeOfOwnershipFee
  },
  has_board_approval: {
    vitecNextImportType: VitecNextImportType.HasBoardApproval,
    vitecNextImportBooleanType: VitecNextImportBooleanType.HasBoardApproval
  },
  has_preemptive: {
    vitecNextImportType: VitecNextImportType.HasPreemptiveRight,
    vitecNextImportBooleanType: VitecNextImportBooleanType.HasPreemptiveRight
  }
}
