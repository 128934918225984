import MaterialDrawer from '@mui/material/Drawer'
import { PropsWithChildren, ReactNode } from 'react'
import Colors from '../../../../pure/libs/Colors'
import { tinyBorderRadius } from '../enums/BorderRadixesDeprecated'
import { SMALL_SPACING_PX } from '../enums/Spacings'
import { DRAWER_WIDTH, DRAWER_WIDTH_STRING } from '../libs/HardCodedSizes'
import Box from './Box'
import { ModalCloseIcon } from './Icons'

export const WithDrawer = ({
  drawerContent,
  children,
  drawerContext
}: PropsWithChildren<{
  drawerContent: ReactNode
  drawerContext: { showDrawer: boolean; setShowDrawer: (showDrawer: boolean) => void }
}>) => {
  const { showDrawer, setShowDrawer } = drawerContext

  const handleDrawerClose = () => {
    setShowDrawer(false)
  }

  return (
    <>
      <MaterialDrawer
        anchor="right"
        open={showDrawer}
        disableEscapeKeyDown
        onClose={handleDrawerClose}
        sx={{
          '& .MuiPaper-root': {
            height: '100%',
            borderTop: `1px solid ${Colors.neutral80}`,
            borderTopLeftRadius: tinyBorderRadius,
            position: 'fixed'
          },
          '& .MuiBackdrop-root': {
            backgroundColor: 'transparent',
            cursor: 'pointer'
          }
        }}
      >
        <Box width={DRAWER_WIDTH_STRING}>{drawerContent}</Box>
      </MaterialDrawer>
      <Box fullWidth fullHeight direction="row">
        <Box fullHeight style={{ width: `calc(100% - ${showDrawer ? DRAWER_WIDTH_STRING : '0px'})` }}>
          {children}
        </Box>
        {showDrawer && (
          <Box width={DRAWER_WIDTH_STRING} position="relative">
            <Box
              position="absolute"
              style={{
                right: `${DRAWER_WIDTH + SMALL_SPACING_PX * 2}px`,
                top: `-${SMALL_SPACING_PX * 3.5}px`
              }}
            >
              <Box pointer>
                <ModalCloseIcon onClick={handleDrawerClose} />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </>
  )
}
