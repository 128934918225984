import ReactDOM from 'react-dom'
import './App.css'
import './Sentry'
import App from './components/App'
import onUnhandledPromiseRejection from './libs/onUnhandledPromiseRejection'
import { GlobalWorkerOptions } from 'pdfjs-dist'

ReactDOM.render(<App />, document.getElementById('root'))

window.addEventListener('unhandledrejection', ({ promise, reason: error }) => onUnhandledPromiseRejection(error))

// Used by PDF Viewer
GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.8.69/pdf.worker.min.mjs'
