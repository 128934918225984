import React, { useEffect, useRef } from 'react'
import { getDocument } from 'pdfjs-dist'

export type BoundingBox = {
  bbox: number[] // [x0, y0, x1, y1]
  text: string
}

export interface PdfViewerProps {
  pdfUrl: string
  highlights: Record<number, BoundingBox[]> // Page number (0-based) to highlights
}

export const PdfViewer: React.FC<PdfViewerProps> = ({ pdfUrl, highlights }) => {
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const renderPdf = async () => {
      if (!containerRef.current) return

      const container = containerRef.current
      container.innerHTML = '' // Clear previous renders

      const pdf = await getDocument(pdfUrl).promise

      // Iterate through each page and render
      for (let pageIndex = 0; pageIndex < pdf.numPages; pageIndex++) {
        const page = await pdf.getPage(pageIndex + 1)
        const viewport = page.getViewport({ scale: 1 })

        // Create a canvas for the page
        const canvas = document.createElement('canvas')
        canvas.width = viewport.width
        canvas.height = viewport.height
        container.appendChild(canvas)

        const ctx = canvas.getContext('2d')
        if (!ctx) continue

        // Render the page onto the canvas
        const renderContext = {
          canvasContext: ctx,
          viewport: viewport
        }
        await page.render(renderContext).promise

        // Draw highlights if any for this page
        const pageHighlights = highlights[pageIndex] || []
        for (const highlight of pageHighlights) {
          const [x0, y0, x1, y1] = highlight.bbox
          ctx.fillStyle = 'rgba(255, 255, 0, 0.5)'
          ctx.fillRect(x0, viewport.height - y1, x1 - x0, y1 - y0)
        }
      }
    }

    renderPdf().catch((err) => console.error('Error rendering PDF:', err))
  }, [pdfUrl, highlights])

  return <div ref={containerRef} style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }} />
}
