import { IsVitecNextProductEnabledResponseCode } from '../../../../pure/leopard/IsVitecNextProductEnabledResponse'
import { smallSpacing } from '../enums/Spacings'
import { useUpload, useVitecNOOrder } from '../hooks/QueryHooks'
import { formatText, getFigmaText } from '../libs/TextRepository'
import { Texts } from '../libs/Texts'
import { VitecNextProductInfoItemLoadingProps } from '../types/VitecNextProductTypes'
import Box from './Box'
import { VitecNextOrderItemStatusItem } from './VitecNextOrderItemStatusItem'
import { VitecNextOrderItemStatusFile } from './VitecNextOrderItemStatusFile'
import { VitecNextOrderItemStatusView } from './VitecNextOrderItemStatusView'

export const VitecNextOrderItemStatus = (props: VitecNextProductInfoItemLoadingProps) => {
  const { isProductEnabled, vitecNextProduct } = props
  const { data: vitecNextOrder } = useVitecNOOrder(props.vitecNextOrder._id)
  const leopardOrder = vitecNextOrder?.jaguarResponses?.[vitecNextProduct]
  const requestId = leopardOrder?.requestId
  const { data: upload } = useUpload(requestId)

  if (!vitecNextOrder) return null

  if (isProductEnabled?.code === IsVitecNextProductEnabledResponseCode.DOCUMENT_NOT_FOUND)
    return <VitecNextOrderItemStatusView textKey={Texts.standardNotificationMissingDocument} type="warning" />

  if (isProductEnabled?.code === IsVitecNextProductEnabledResponseCode.ADDRESS_NOT_FOUND)
    return (
      <VitecNextOrderItemStatusView
        textKey={Texts.AreaDescriptionMissingArea}
        type="warning"
        text={formatText(getFigmaText(Texts.AreaDescriptionMissingArea), [vitecNextOrder?.address || ''])}
      />
    )

  const successText = !!leopardOrder?.fulfilledAt ? getFigmaText(Texts.standardNotificationUploadComplete) : undefined

  return (
    <Box fullWidth>
      <VitecNextOrderItemStatusFile {...props} />
      <Box top spacing={smallSpacing} fullWidth>
        <VitecNextOrderItemStatusItem
          {...props}
          vitecNextOrder={vitecNextOrder}
          vitecNextProduct={vitecNextProduct}
          upload={upload}
          isProductEnabled={isProductEnabled}
          successText={successText}
        />
      </Box>
    </Box>
  )
}
