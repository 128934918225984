import { NavigationType, useNavigate, useNavigationType } from 'react-router-dom'
import { smallSpacing } from '../enums/Spacings'
import { Texts } from '../libs/Texts'
import Box from './Box'
import { VitecNextOrderArrowBackIcon } from './Icons'
import { NoWrapFigmaText } from './StyledComponents'
import { format } from 'path'
import { formatRoutePathVitexNextOrder } from '../../../../pure/libs/RoutePath'

export function VitecNextOrderProductBackButton({ vitecNextOrderId }: { vitecNextOrderId: string }) {
  const navigate = useNavigate()

  return (
    <Box
      direction="row"
      link
      onClick={() => {
        navigate(formatRoutePathVitexNextOrder(vitecNextOrderId))
      }}
    >
      <Box>
        <VitecNextOrderArrowBackIcon />
      </Box>
      <Box left spacing={smallSpacing}>
        <NoWrapFigmaText textKey={Texts.standardBack} />
      </Box>
    </Box>
  )
}
