import { ReactComponent as iconGoogleImg } from '../assets/deprecated/images/iconGoogle.svg'
export const iconGoogle = iconGoogleImg

import { ReactComponent as menuLogoImg } from '../assets/deprecated/images/menuLogo.svg'
export const menuLogo = menuLogoImg

import { ReactComponent as companyMixedLogoImg } from '../assets/deprecated/images/companyMixedLogo.svg'
export const companyMixedLogo = companyMixedLogoImg

import { ReactComponent as office365Img } from '../assets/deprecated/images/office365.svg'
export const office365 = office365Img
