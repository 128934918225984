import { getDownloadURL, ref } from 'firebase/storage'
import { LeopardOrder } from '../../../../pure/types/LeopardOrder'
import { minSpacing, smallSpacing } from '../enums/Spacings'
import { storage } from '../libs/Firebase'
import Images from '../libs/Images'
import { Texts } from '../libs/Texts'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'

export function VitecNextProductSourceFileInfo({ leopardOrder }: { leopardOrder?: LeopardOrder }) {
  const sourceFile = Object.entries(leopardOrder?.documentUrls || {})[0]

  const [fileName, link] = sourceFile || []

  if (!sourceFile) return null

  const onClick = () => getDownloadURL(ref(storage, link)).then((url) => window.open(url, '_blank'))

  return (
    <Box direction="row" fullWidth link onClick={onClick}>
      <Box>
        <FigmaImageContainer imageKey={Images.pngDocIcon} />
      </Box>
      <Box left spacing={smallSpacing}>
        <FigmaText textKey={Texts.standardDocumentSourceLabel} />
      </Box>
      <Box left spacing={minSpacing}>
        <FigmaText textKey={Texts.standardDocumentSourceFileName} text={fileName} />
      </Box>
    </Box>
  )
}
