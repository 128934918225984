import { useEffect } from 'react'
import { VitecNOOrderQueryParams } from '../../../../pure/types/VitecNODerivedTypes'
import { signInWithCustomToken } from '../libs/FirebaseHelper'
import useQueryParams from '../libs/useQueryParams'
import { useVitecNOOrder } from './QueryHooks'

type Props = {
  onFinally?: () => unknown
}

export default function useSignInUserWithVitecNextOrder(props: Props) {
  const { orderId: vitecNextOrderId } = useQueryParams<VitecNOOrderQueryParams>()
  const { data: vitecNextOrder, isLoading } = useVitecNOOrder(vitecNextOrderId)

  useEffect(() => {
    if (!isLoading && !vitecNextOrder?.authToken) props.onFinally?.()

    vitecNextOrder?.authToken &&
      signInWithCustomToken(vitecNextOrder?.authToken)
        .catch((err) => ({})) // Silence this error since it will happen a lot
        .finally(props.onFinally)
  }, [vitecNextOrder?.authToken])
}
