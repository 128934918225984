import styled from '@emotion/styled'
import React from 'react'
import Images from '../../../../pure/libs/AppImages'
import { GAZELLE_WEBSITE_URL } from '../../../../pure/libs/Consts'
import { TextKey } from '../../../../pure/types/Antiloop'
import { defaultSpacing, reallyBigSpacing, tinySpacing } from '../enums/Spacings'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import SelectFieldWithLabel from './SelectFieldWithLabel'
import { ONBOARDING_MAX_WIDTH } from '../libs/HardCodedSizes'
import { toPx } from '../../../../pure/libs/Common'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'

export const LANGUAGES_OBJECT = { sv: 'Svenska', en: 'English' }
export const getLanguageOptions = (obj) =>
  Object.keys(obj).map((code) => ({
    key: LANGUAGES_OBJECT[code],
    value: code
  }))

type Props = {
  children: React.ReactNode
  headingTextKey?: TextKey
  subheadingTextKey?: TextKey
}

const SignInSignUpLayout: React.FC<Props> = ({ children, headingTextKey, subheadingTextKey }) => {
  const showTitlesWrapper = !!headingTextKey || !!subheadingTextKey

  return (
    <Box fullWidth align="center" spacing={defaultSpacing} fullHeight>
      <Box width={ONBOARDING_MAX_WIDTH} top spacing={reallyBigSpacing}>
        <Box link onClick={() => window.open(GAZELLE_WEBSITE_URL, '_blank')} top fullWidth align="center">
          <FigmaImageContainer imageKey={Images.companyMixedLogo} width={toPx(ONBOARDING_MAX_WIDTH) / 2} />
        </Box>
        {showTitlesWrapper && (
          <Box fullWidth align="center" spacing={defaultSpacing} alignText="center">
            <Box top>
              <FigmaTextWithStyle type="h5" textKey={headingTextKey} />
            </Box>
          </Box>
        )}
        <Box fullWidth align="center" top>
          {children}
        </Box>
      </Box>
    </Box>
  )
}

export default SignInSignUpLayout

export const TextFieldStyles = `
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  
  & .MuiSelect-icon {
    display: none;
  }

  cursor: pointer;
`
