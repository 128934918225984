import styled from '@emotion/styled'
import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'
import { Purple } from '../../../../pure/libs/Colors'
import { BorderRadixes } from '../enums/BorderRadixes'
import { SMALL_SPACING_PX, defaultSpacing, smallSpacing } from '../enums/Spacings'
import { Texts } from '../libs/Texts'
import { VitecNextProductInfoItem } from '../types/VitecNextProductTypes'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import { VitecNextProductInfoItems } from './VitecNextProductInfoItems'

export const ICON_BOX_SIZE = 75

export function VitecNextOrderItemIconView(props: { vitecNextProduct: VitecNextProduct }) {
  const { vitecNextProduct } = props
  const vitecNextProductInfoItem: VitecNextProductInfoItem = VitecNextProductInfoItems[vitecNextProduct]
  const { imageKey, enableBetaUX = false } = vitecNextProductInfoItem

  const IconContainerComponent = enableBetaUX ? BetaIconContainer : Box

  return (
    <Box fullWidth style={{ height: ICON_BOX_SIZE + SMALL_SPACING_PX * 5 }}>
      <IconContainerComponent fullWidth align="center" fullPadding spacing={smallSpacing} position="relative">
        {enableBetaUX && (
          <Box position="absolute" style={{ right: smallSpacing }}>
            <BetaButton />
          </Box>
        )}
        <Box style={{ width: ICON_BOX_SIZE, height: ICON_BOX_SIZE }} align="center" justify="center">
          <FigmaImageContainer imageKey={imageKey} />
        </Box>
      </IconContainerComponent>
    </Box>
  )
}
export const BetaIconContainer = styled(Box)`
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle, rgba(203, 46, 114, 1) 0%, rgba(255, 98, 98, 0.5) 99%);
    opacity: 0.3;
    z-index: 1;
  }
`

/**
 * 
 *   > * {
    position: relative;
    z-index: 2;
  }
 * 
 */

export function BetaButton() {
  return (
    <BetaButtonContainer spacing={defaultSpacing} left right>
      <FigmaText textKey={Texts.standardBetaLabel} />
    </BetaButtonContainer>
  )
}

const BetaButtonContainer = styled(Box)`
  background-color: ${Purple};
  border-radius: ${BorderRadixes.regular};
`
