import useAppState from '../hooks/useAppState'
import useIsMobile from '../hooks/useIsMobile'
import MenuLoggedIn from './MenuLoggedIn'
import MenuLoggedOut from './MenuLoggedOut'
import useFirebaseUser from './useFirebaseUser'

export default function Menu() {
  const firebaseUser = useFirebaseUser().data

  if (!firebaseUser?.email) return <MenuLoggedOut />

  return <MenuLoggedIn />
}
