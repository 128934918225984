import { SendGridTemplateIds } from '../enums/SendGridTemplateIds'
import { isProcessingUpload } from '../libs/VitecNextOrderItemHelper'
import { Upload } from '../types/JaguarTypes'
import { VitecNextProduct } from './VitecNextProduct'

type VitexNextProductPureInfoItem = {
  onUploadDoneEmailTemplateId?: string
  vitecNextProductExperimental?: VitecNextProduct
}

export const VitecNextProductPureInfoItems: {
  [key in VitecNextProduct]: VitexNextProductPureInfoItem
} = {
  [VitecNextProduct.IMAGE_CAPTIONS]: {
    onUploadDoneEmailTemplateId: SendGridTemplateIds.TEXTS_READY_FOR_PREVIEW
  },
  [VitecNextProduct.CONSTRUCTION_INFO]: {
    onUploadDoneEmailTemplateId: SendGridTemplateIds.TEXTS_READY_FOR_PREVIEW
  },
  [VitecNextProduct.SHORT_LONG_DESCRIPTION]: {},
  [VitecNextProduct.BASIC_ASSOCIATION]: {},
  [VitecNextProduct.SELF_DECLERATION]: {},
  [VitecNextProduct.DESCRIPTIONS]: {},
  [VitecNextProduct.AREA_DESCRIPTION]: {}
}

export function shouldShowLongWaitMessage({
  upload,
  vitecNextProduct
}: {
  upload: Upload | undefined
  vitecNextProduct: VitecNextProduct
}) {
  return shouldSendOnUploadDoneInfoEmail({ vitecNextProduct }) && isProcessingUpload(upload)
}

export function shouldSendOnUploadDoneInfoEmail({ vitecNextProduct }: { vitecNextProduct: VitecNextProduct }) {
  return !!VitecNextProductPureInfoItems[vitecNextProduct]?.onUploadDoneEmailTemplateId
}
