import Box from './Box'

// Example highlights
const highlights = {
  0: [
    // Page 1
    { bbox: [100, 150, 300, 200], text: 'Important text here' },
    { bbox: [200, 300, 400, 350], text: 'Another important point' }
  ],
  1: [
    // Page 2
    { bbox: [50, 100, 200, 150], text: 'Key content' }
  ]
}

export const SandboxPage = () => {
  return (
    <Box fullWidth fullHeight direction="row">
      <Box fullWidth fullHeight style={{ backgroundColor: 'blue' }}></Box>
      <Box fullWidth fullHeight style={{ backgroundColor: 'red' }}></Box>
    </Box>
  )
}
