export enum VitecNextProduct {
  IMAGE_CAPTIONS = 'IMAGE_CAPTIONS',
  CONSTRUCTION_INFO = 'CONSTRUCTION_INFO',
  SHORT_LONG_DESCRIPTION = 'SHORT_LONG_DESCRIPTION',
  BASIC_ASSOCIATION = 'BASIC_ASSOCIATION',
  SELF_DECLERATION = 'SELF_DECLERATION',
  DESCRIPTIONS = 'DESCRIPTIONS',
  AREA_DESCRIPTION = 'AREA_DESCRIPTION'
}

export const LiveProducts: { [key in VitecNextProduct]: boolean } = {
  IMAGE_CAPTIONS: true,
  CONSTRUCTION_INFO: true,
  SHORT_LONG_DESCRIPTION: true,
  BASIC_ASSOCIATION: true,
  SELF_DECLERATION: true,
  AREA_DESCRIPTION: true,
  DESCRIPTIONS: false
}

export const LiveProductNames = Object.entries(LiveProducts)
  .filter(([_, value]) => value)
  .map(([key]) => key as VitecNextProduct)
