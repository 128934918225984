import Images from '../../../../pure/libs/AppImages'
import { TextsDeprecated } from '../../../../pure/libs/TextsDeprecated'
import { SignInViewProps } from '../../../../pure/types/SignInTypes'
import { smallSpacing } from '../enums/Spacings'
import Box from './Box'
import ContinueWithButton from './ContinueWithButton'

export function SignInWithAuthProviders(signInViewProps: SignInViewProps) {
  const { onLoginWithGoogle, onLoginWithMicrosoft } = signInViewProps

  return (
    <Box fullWidth>
      <Box fullWidth>
        <ContinueWithButton
          onLogin={onLoginWithGoogle}
          textKey={TextsDeprecated.onboardingAuthProviderGoogleButtonText}
          imageKey={Images.iconGoogle}
        />
      </Box>
      <Box top fullWidth spacing={smallSpacing}>
        <ContinueWithButton
          onLogin={onLoginWithMicrosoft}
          textKey={TextsDeprecated.onboardingAuthProviderMicrosoftButtonText}
          imageKey={Images.office365}
        />
      </Box>
    </Box>
  )
}
