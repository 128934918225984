import { FeatureFlag } from '../../../../pure/enums/FeatureFlags'
import { VitecNOOrderQueryParams } from '../../../../pure/types/VitecNODerivedTypes'
import { useVitecNOOrder } from '../hooks/QueryHooks'
import { useFeatureFlag } from '../hooks/useFeatureFlag'
import useQueryParams from '../libs/useQueryParams'
import AppContainer from './AppContainer'
import { LoadingPage } from './LoadingPage'
import SignIn from './SignIn'
import { isElligebleForNonAuthenticatedJaguarOrderCreation } from '../../../../pure/libs/isElligebleForNonAuthenticatedJaguarOrderCreation'

export const Root = () => {
  const { orderId } = useQueryParams<VitecNOOrderQueryParams>()
  const { data: vitecNextOrder, isLoading } = useVitecNOOrder(orderId)
  const enableAuthentication = useFeatureFlag(FeatureFlag.enableAuthentication)

  const orderPlaced = vitecNextOrder?.orderPlaced
  const elligebleForNonAuthenticatedJaguarOrderCreation = isElligebleForNonAuthenticatedJaguarOrderCreation(orderPlaced)

  if (isLoading) return <LoadingPage />

  if (elligebleForNonAuthenticatedJaguarOrderCreation) return <AppContainer />

  if (!enableAuthentication) return <AppContainer />

  return <SignIn />
}
