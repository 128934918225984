import styled from '@emotion/styled'
import MaterialTextField, { TextFieldProps } from '@mui/material/TextField'
import { tinyBorderRadius } from '../enums/BorderRadixesDeprecated'
import { createShouldForwardProp } from '../libs/EmotionHelper'
import Colors from '../../../../pure/libs/Colors'
import { getFigmaText } from '../libs/TextRepository'
import { TextKey } from '../../../../pure/types/Antiloop'
import { PropsWithChildren } from 'react'

export type TextFieldOutlinedProps = {
  labelTextKey?: TextKey
  placeholderTextKey?: TextKey
  pointer?: boolean
  className?: string
  Component?: React.FC<PropsWithChildren<TextFieldProps>>
} & TextFieldProps

export const TextField = (props: TextFieldOutlinedProps) => {
  const { labelTextKey, placeholderTextKey, Component = BaseTextField, ...rest } = props

  return (
    <Component
      variant="outlined"
      size="small"
      label={labelTextKey ? getFigmaText(labelTextKey) : ''}
      placeholder={placeholderTextKey ? getFigmaText(placeholderTextKey) : undefined}
      InputLabelProps={{ shrink: false }}
      style={{ width: props.fullWidth ? '100%' : undefined }}
      {...rest}
    />
  )
}

export const TextFieldOutlined = TextField

// Is duplicated in see TextFieldOutlinedNumberInputStyles
export const BaseTextFieldStyles = `
  & .MuiOutlinedInput-notchedOutline {
    border-radius: ${tinyBorderRadius};
  }

  & legend {
    display: none;
  }

  & fieldset {
    top: 0;
  }

  background-color: white;
  cursor: pointer;
`
// Is duplicated in see BaseTextFieldStyles
export const TextFieldOutlinedNumberInputStyles = {
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: tinyBorderRadius,
    border: `1px solid ${Colors.neutral80}`
  },
  '& legend': {
    display: 'none'
  },

  '& fieldset': {
    top: 0
  },

  backgroundColor: 'white',
  cursor: 'pointer'
}

export const BaseTextField = styled(MaterialTextField, {
  shouldForwardProp: createShouldForwardProp('multiline')
})`
  ${BaseTextFieldStyles}
`
