import { Language } from '../../../../pure/types/Antiloop'
import { State } from './useAppState'
import { StorageKey, StorageType, getElligebleStorage } from './useLocalStorageItemHelper'

// use tiger use language instead
export const _getLanguageCode = (): Language | undefined => {
  if (typeof window === 'undefined') return

  const storage = getElligebleStorage(StorageType.LOCAL_STORAGE)
  const json = storage.getItem(StorageKey.STATE)
  if (!json) return

  return
}

export function _getDefaultLanguageCode(_navigator?: Navigator): Language | undefined {
  if (!_navigator && typeof navigator === 'undefined') return
  _navigator = _navigator || navigator
  return _navigator.language?.substr(0, 2) as Language | undefined
}

export function _getDefaultCountryCode(_navigator?: Navigator): string | undefined {
  if (!_navigator && typeof navigator === 'undefined') return
  _navigator = _navigator || navigator
  return navigator.language.split('-')[1]
}
