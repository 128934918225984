import JsonTexts from '../assets/texts.json'

export const Texts = JsonTexts
export const AppTexts = JsonTexts

export const DeprecatedTexts = {
  keywordsPropertySelected: {
    name: 'keywordsPropertySelected',
    characters: 'Selected',
    style: {
      fontFamily: 'Inter',
      fontSize: '20px',
      fontWeight: 400,
      letterSpacing: 0,
      color: 'rgba(255, 255, 255, 1)'
    },
    pageName: 'UX Project',
    figmaTextKeyName: 'CSS_KeywordsPropertySelected'
  }
}
