import styled from '@emotion/styled'
import { useState } from 'react'
import { FeatureFlag } from '../../../../pure/enums/FeatureFlags'
import { SelfDeclarationResults } from '../../../../pure/leopard/JaguarTypesDerived'
import Colors from '../../../../pure/libs/Colors'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { BorderRadixes } from '../enums/BorderRadixes'
import { bigSpacing, smallSpacing } from '../enums/Spacings'
import { useUpload, useVitecNOOrder } from '../hooks/QueryHooks'
import { useFeatureFlag } from '../hooks/useFeatureFlag'
import { useFileUrl } from '../hooks/useFileUrl'
import { useJaguarResults } from '../hooks/useJaguarResults'
import { VITEC_NEXT_ORDER_PRODUCT_CONTAINER_WIDTH } from '../libs/HardCodedSizes'
import Images from '../libs/Images'
import { Texts } from '../libs/Texts'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import FigmaTextWithLinebreaks from './FigmaTextWithLinebreaks'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'
import { JsonView } from './JsonView'
import Loader from './Loader'
import { LoadingPage } from './LoadingPage'
import { BoundingBox, PdfViewer } from './PdfViewer'
import { VitecNextOrderProductBackButton } from './VitecNextOrderProductBackButton'
import { VitecNextOrderProductButtons } from './VitecNextOrderProductButtons'
import { VitecNextOrderProductFeedbackButton } from './VitecNextOrderProductFeedbackButton'
import { VitecNextOrderProductHeader } from './VitecNextOrderProductHeader'
import { VitecNextProductInfoItems } from './VitecNextProductInfoItems'
import { VitecNextProductSourceFileInfo } from './VitecNextProductSourceFileInfo'
import { WithDrawer } from './WithDrawer'

export const VitecNextOrderProductSelfDeclaration = (props: VitecNextOrderProductProps) => {
  const enableDebug = useFeatureFlag(FeatureFlag.enableDebug)
  const [showDrawer, setShowDrawer] = useState(false)
  const drawerContext = { showDrawer, setShowDrawer }
  const { vitecNextOrderId, vitecNextProduct } = props
  const { data: vitecNextOrder, isLoading: isLoadingVitecNextOrder } = useVitecNOOrder(vitecNextOrderId)
  const item = VitecNextProductInfoItems[vitecNextProduct]
  const leopardOrder = vitecNextOrder?.jaguarResponses?.[vitecNextProduct]
  const { requestId } = leopardOrder || {}
  const { data: fileUrl } = useFileUrl({ leopardOrder })

  const { data: upload } = useUpload(leopardOrder?.requestId || '')

  const { data: jaguarResults, isLoading: isLoadingJaguarResults } = useJaguarResults<SelfDeclarationResults>(
    vitecNextOrderId,
    requestId
  )

  const isLoading = isLoadingVitecNextOrder || isLoadingJaguarResults

  if (!vitecNextOrder) return <Box />
  if (!leopardOrder) return <Box />

  let highlights = {}
  if (vitecNextOrder.estateId === ENSJO_VAGEN_ESTATE_ID && jaguarResults) {
    //@ts-ignore
    jaguarResults.results = ENSJO_VAGEN_RESULT
    highlights = getHighlights(jaguarResults)
  }

  return (
    <WithDrawer
      drawerContent={fileUrl && <PdfViewer pdfUrl={fileUrl} highlights={highlights} />}
      drawerContext={drawerContext}
    >
      <Box fullWidth fullPadding direction="row" justify="space-between" spacing={bigSpacing}>
        <Box>
          <VitecNextOrderProductBackButton vitecNextOrderId={vitecNextOrderId} />
        </Box>
        <Box fullWidth width={`${VITEC_NEXT_ORDER_PRODUCT_CONTAINER_WIDTH}px`}>
          <VitecNextOrderProductHeader
            vitecNextOrder={vitecNextOrder}
            headerTextKey={item.textKey}
            vitecNextProduct={vitecNextProduct}
          />

          <Box top>
            <FigmaImageContainer imageKey={Images.gazelleInCircle} />
          </Box>

          <Box fullWidth top spacing={smallSpacing}>
            <VitecNextOrderProductButtons vitecNextOrder={vitecNextOrder} vitecNextProduct={vitecNextProduct} />
          </Box>

          <Box fullWidth fullPadding>
            <Box fullWidth onClick={() => setShowDrawer(true)}>
              <VitecNextProductSourceFileInfo leopardOrder={leopardOrder} />
            </Box>
          </Box>

          {upload?.status === 'processing' && <LoadingPage />}
          {upload?.status === 'done' && (
            <Box fullWidth>
              <Box fullWidth>
                <Container fullWidth fullPadding position="relative">
                  <Box direction="row" align="center">
                    <FigmaText textKey={Texts.ConstructionCiTextHeader} />
                  </Box>
                  <Box top>
                    <VitecNextOrderProductFeedbackButton {...props} />
                  </Box>
                  {isLoading && (
                    <Box fullWidth align="center" fullPadding>
                      <Loader />
                    </Box>
                  )}
                  <Box fullWidth top>
                    {jaguarResults?.results
                      .filter((r) => r.answer)
                      .sort((a, b) => parseInt(a.question_number) - parseInt(b.question_number))
                      .map((r) => (
                        <Box top spacing={smallSpacing}>
                          <FigmaTextWithStyle
                            separator={<span />}
                            separatorWhenEmptyContent={<br />}
                            type="body3"
                            text={r.question}
                          />
                          <FigmaTextWithStyle
                            Component={FigmaTextWithLinebreaks as any}
                            separator={<span />}
                            separatorWhenEmptyContent={<br />}
                            type="body2"
                            text={r.answer}
                          />
                        </Box>
                      ))}
                  </Box>
                  {enableDebug && <JsonView data={jaguarResults || {}} />}
                </Container>
              </Box>
            </Box>
          )}
        </Box>
        <Box />
      </Box>
    </WithDrawer>
  )
}

const Container = styled(Box)`
  background-color: ${Colors.white};
  border-radius: ${BorderRadixes.subtle};
`

const ENSJO_VAGEN_ESTATE_ID = 'C99699D3-B3CD-4EA4-BDFD-D130995DFBB7'

const ENSJO_VAGEN_RESULT = [
  {
    question_number: 1,
    question: 'Vet du om det er eller har vært feil eller skader på bad/våtrom?',
    answer:
      'Fra forrige eier ble det beskrevet sprekk i veggflis på bad, noe som også fremkommer i nåværende rapport. Det\nble også nevnt tidligere vannskade på tak, men skal angivelig ha blitt ordnet. Intet nytt å bemerke siden den gang',
    confidence: 1.0,
    bounding_boxes: [
      { page: 1, bounding_box: [32.095088958740234, 712.9825439453125, 323.8793640136719, 723.50567626953] }
    ]
  },
  {
    question_number: 11,
    question: 'Vet du om det er gjort arbeid på vann og avløp, enten av deg eller tidligere eiere?',
    answer: 'Ja',
    confidence: 1.0,
    bounding_boxes: [
      { page: 1, bounding_box: [36.22008514404297, 29.58249282836914, 409.6060485839844, 40.105628967285156] }
    ]
  },
  {
    question_number: 17,
    question: 'Vet du om det er gjort arbeid på det elektriske anlegget, enten av deg eller tidligere eiere?',
    answer: 'Ja',
    confidence: 1.0,
    bounding_boxes: [
      { page: 2, bounding_box: [37.49509048461914, 374.6200256347656, 450.90106201171875, 385.1431579589844] }
    ]
  },
  {
    question_number: 32,
    question: 'Har du andre opplysninger om boligen eller eiendommen, utover det du har svart?',
    answer:
      'Mulighet for leie av p-plass på eiendommen ved etterspørsel, men her er det venteliste. Det er også mulighet for\nå benytte et felles vaskeri og sykkelbod som også er tilknyttet bygget.',
    confidence: 1.0,
    bounding_boxes: [
      { page: 3, bounding_box: [40.39372253417969, 172.15748596191406, 424.4933166503906, 181.97975158691406] }
    ]
  }
]

const getHighlights = (results: SelfDeclarationResults) => {
  const highlights: Record<number, BoundingBox[]> = {}
  results.results.forEach((result) => {
    //@ts-ignore'
    result.bounding_boxes.forEach((boundingBox) => {
      const page = boundingBox.page
      if (!highlights[page]) highlights[page] = []
      highlights[page].push({
        bbox: boundingBox.bounding_box,
        text: result.answer
      })
    })
  })
  return highlights
}
