import { IsVitecNextProductEnabledResponse } from '../../../../pure/leopard/IsVitecNextProductEnabledResponse'
import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'
import { formatRoutePathVitecNextOrderProduct } from '../../../../pure/libs/RoutePath'
import { LeopardOrder } from '../../../../pure/types/LeopardOrder'
import { VitecNextOrder } from '../../../../pure/types/VitecNODerivedTypes'
import { smallSpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { useAsyncFunction } from '../hooks/useAsyncFunction'
import { useNavigate } from '../hooks/useNavigate'
import { isVitecNextProductEnabled } from '../libs/CloudFunctionsApiHandler'
import Images from '../libs/Images'
import { IsVitecNextProductEnabledInfo } from '../libs/IsVitecNextProductEnabledHelper'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../libs/Texts'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import { InfoIcon } from './Icons'
import { LoaderGreen } from './LoaderGreen'
import Tooltip from './Tooltip'
import { VitecNextProductInfoItems } from './VitecNextProductInfoItems'

export function VitecNextOrderLandingItem({
  vitecNextProduct,
  vitecNextOrder
}: {
  vitecNextProduct: VitecNextProduct
  vitecNextOrder: VitecNextOrder
}) {
  const navigate = useNavigate()
  const vitecNextOrderId = vitecNextOrder?._id
  const leopardOrder: LeopardOrder | undefined = vitecNextOrder?.jaguarResponses?.[vitecNextProduct]

  const { textKey } = VitecNextProductInfoItems[vitecNextProduct]

  const { state } = useAppState()
  const { data: isProductEnabled } = useAsyncFunction<IsVitecNextProductEnabledResponse>(async () =>
    isVitecNextProductEnabled({ vitecNextOrderId, vitecNextProduct }, state)
  )

  const code = isProductEnabled?.code
  const isWarning = !!code
  const warningTooltipTitle =
    (code && IsVitecNextProductEnabledInfo[code].getText({ vitecNextOrder, isProductEnabled, vitecNextProduct })) || ''
  const errorToolTipTitle =
    leopardOrder?.metaData?.uploadStatus === 'error' ? getFigmaText(Texts.standardNotificationCiError) : ''

  const tooltipText = errorToolTipTitle || warningTooltipTitle

  const isDone = leopardOrder?.metaData?.uploadStatus === 'done'

  return (
    <Tooltip title={tooltipText} placement="left">
      <Box
        fullWidth
        direction="row"
        align="center"
        link={isDone}
        onClick={() => navigate(formatRoutePathVitecNextOrderProduct({ vitecNextOrderId, vitecNextProduct }))}
        pointer={!!tooltipText}
      >
        <Box>
          <VitecNextOrderLandingItemStatusIcon leopardOrder={leopardOrder} isProductEnabled={isProductEnabled} />
        </Box>
        <Box left spacing={smallSpacing}>
          <FigmaText textKey={Texts.cssDashboardAllProgressProductName} text={getFigmaText(textKey)} />
        </Box>
        {!leopardOrder && isWarning && (
          <Box left spacing={smallSpacing}>
            <InfoIcon color="black" />
          </Box>
        )}
      </Box>
    </Tooltip>
  )
}

const VitecNextOrderLandingItemStatusIcon = ({
  leopardOrder,
  isProductEnabled
}: {
  leopardOrder?: LeopardOrder
  isProductEnabled?: IsVitecNextProductEnabledResponse
}) => {
  const code = isProductEnabled?.code

  switch (true) {
    case leopardOrder?.metaData?.uploadStatus === 'done':
      return <FigmaImageContainer imageKey={Images.greenRoundedCheckmark} />
    case leopardOrder?.metaData?.uploadStatus === 'error':
      return <FigmaImageContainer imageKey={Images.redErrorTriangle} />
    case !!code:
      return <FigmaImageContainer imageKey={Images.yellowWarningTriangle} />
    default:
      return <LoaderGreen />
  }
}
