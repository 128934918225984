import { StyledEngineProvider, Theme, ThemeProvider } from '@mui/material'
import StylesProvider from '@mui/styles/StylesProvider'
import 'react-json-view-lite/dist/index.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import RoutePath from '../../../../pure/libs/RoutePath'
import { AppStateContext, useAppStateContext } from '../hooks/useAppState'
import { AppSessionContext, useAppStateSessionContext } from '../hooks/useAppStateSession'
import { OnMountApp } from '../hooks/useOnMountApp'
import '../libs/Fonts'
import theme from '../libs/theme'
import { DashboardPage } from '../pages/DashboardPage'
import { VitecNextFAQPage } from '../pages/VitecNextFAQPage'
import { VitecNextOrderLandingPage } from '../pages/VitecNextOrderLandingPage'
import { VitecNextOrderPage } from '../pages/VitecNextOrderPage'
import { VitecNextOrderProductFeedbackPage } from '../pages/VitecNextOrderProductFeedbackPage'
import { VitecNextOrderProductPage } from '../pages/VitecNextOrderProductPage'
import ErrorBoundaryProvider from './ErrorBoundaryProvider'
import { Helmet } from './Helmet'
import { PrivacyPolicyIframe, TermsAndConditionsIframe } from './Iframes'
import { Root } from './Root'
import { SandboxPage } from './SandboxPage'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const App = () => {
  const appStateContext = useAppStateContext()
  const appStateSessionContext = useAppStateSessionContext()

  return (
    <ErrorBoundaryProvider>
      <StylesProvider injectFirst>
        <AppStateContext.Provider value={appStateContext}>
          <AppSessionContext.Provider value={appStateSessionContext}>
            <BrowserRouter>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                  <Helmet titleTemplate="%s - Gazelle" />
                  <OnMountApp />
                  <Routes>
                    <Route path={RoutePath.ROOT} Component={Root}>
                      <Route path={RoutePath.ROOT} Component={DashboardPage} />
                      <Route path={RoutePath.VITEC_NEXT_ORDER_LANDING} Component={VitecNextOrderLandingPage} />
                      <Route path={RoutePath.VITEC_NEXT_ORDER} Component={VitecNextOrderPage} />
                      <Route path={RoutePath.VITEC_NEXT_ORDER_PRODUCT} Component={VitecNextOrderProductPage} />
                      <Route
                        path={RoutePath.VITEC_NEXT_ORDER_PRODUCT_FEEDBACK}
                        Component={VitecNextOrderProductFeedbackPage}
                      />
                    </Route>

                    <Route path={RoutePath.SANDBOX} Component={SandboxPage} />
                    <Route path={RoutePath.TERMS_AND_CPNDITIONS} Component={TermsAndConditionsIframe} />
                    <Route path={RoutePath.PRIVACY_POLICY} Component={PrivacyPolicyIframe} />
                    <Route path={RoutePath.VITEC_NEXT_FAQ} Component={VitecNextFAQPage} />
                  </Routes>
                  <ToastContainer />
                </ThemeProvider>
              </StyledEngineProvider>
            </BrowserRouter>
          </AppSessionContext.Provider>
        </AppStateContext.Provider>
      </StylesProvider>
    </ErrorBoundaryProvider>
  )
}

export default App

//
