import moment from 'dayjs'
import { ServerFirebaseUser } from '../types/ServerTypes.js'
import { BaseObject } from '../types/types.js'
import { createUniqueId } from './Common.js'

// eslint-disable-next-line prettier/prettier
export const toUpdatedObject = <T extends BaseObject>(object: T, u?: ServerFirebaseUser): T => ({ ...object, updatedAt: moment().format() });

type MapFunction = (item: any) => any

export const mapQueryResponse = (response: any, mapFn?: MapFunction) =>
  response.docs ? getCollectionData(response, mapFn) : getDocData(response, mapFn)

// Get array of doc data from collection
export const getCollectionData = (collection, mapFn?: MapFunction) =>
  collection.docs.map((doc) => getDocData(doc, mapFn))

// Get doc data and merge doc.id
export function getDocData(doc, mapFn?: MapFunction) {
  if (!doc.exists) return null
  const data = { _id: doc.id, id: doc.id, ...doc.data() }
  return mapFn ? mapFn(data) : data
}

export const toBaseObject = (): BaseObject => ({
  _id: createUniqueId(),
  createdAt: moment().format()
})
