import { getDownloadURL, ref } from 'firebase/storage'
import { useEffect, useState } from 'react'
import { LeopardOrder } from '../../../../pure/types/LeopardOrder'
import { storage } from '../libs/Firebase'

export function useFileUrl({ leopardOrder }: { leopardOrder?: LeopardOrder }) {
  const [url, setUrl] = useState<string | null>(null)
  const [loading, setLoading] = useState(true)
  const sourceFile = Object.entries(leopardOrder?.documentUrls || {})[0]

  const [_, link] = sourceFile || []

  useEffect(() => {
    if (!link) return
    getDownloadURL(ref(storage, link))
      .then((url) => setUrl(url))
      .finally(() => setLoading(false))
  }, [link])

  return { data: url, loading }
}
