import React from 'react'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { smallSpacing } from '../enums/Spacings'
import { useUpload, useVitecNOOrder } from '../hooks/QueryHooks'
import useAppState from '../hooks/useAppState'
import { updateUpload } from '../libs/DBApiHandler'
import { captureAndNotifyError } from '../libs/ErrorHelper'
import Images from '../libs/Images'
import { Texts } from '../libs/Texts'
import Box from './Box'
import Button from './Button'
import { VitecNextOrderItemStatusView } from './VitecNextOrderItemStatusView'

export const VitecNextOrderProductButtonsFeedback = (
  props: {
    feedbackText: string
  } & VitecNextOrderProductProps
) => {
  const { vitecNextOrderId, vitecNextProduct, feedbackText } = props
  const [isLoading, setIsLoading] = React.useState(false)
  const { data: vitecNextOrder } = useVitecNOOrder(vitecNextOrderId)
  const leopardOrder = vitecNextOrder?.jaguarResponses?.[vitecNextProduct]
  const [isSuccess, setIsSuccess] = React.useState(false)
  const { data: upload } = useUpload(leopardOrder?.requestId || '')

  const onClick = () =>
    upload &&
    Promise.resolve()
      .then(() =>
        updateUpload({
          ...upload,
          // TODO REMOVE, this is a hack // https://gazellework.atlassian.net/browse/GTB-203
          metaData: {
            userId: vitecNextOrder?.userid || '',
            vitecNextOrderId
          },
          feedbackText
        })
      )
      .then(() => setIsSuccess(true))
      .catch(captureAndNotifyError)

  if (isSuccess)
    return (
      <Box>
        <VitecNextOrderItemStatusView type="success" textKey={Texts.standardFeedbackSent} />
      </Box>
    )

  return (
    <Button
      buttonType="secondary"
      textKey={Texts.standardFeedbackSend}
      spacing={smallSpacing}
      onClick={onClick}
      loading={isLoading}
      imageKey={Images.uploadArrowWhite}
    />
  )
}
