import { VitecNextOrder } from '../../../../pure/types/VitecNODerivedTypes.js'
import { formatText, getFigmaText } from '../libs/TextRepository.js'
import { Texts } from '../libs/Texts.js'
import FigmaText, { FigmaTextProps } from './FigmaText.js'

export function VitecNextOrderItemLongWaitFigmaText({
  vitecNextOrder,
  figmaTextProps
}: {
  vitecNextOrder?: VitecNextOrder
  figmaTextProps?: FigmaTextProps
}) {
  if (!vitecNextOrder) return null
  return (
    <FigmaText
      {...figmaTextProps}
      textKey={Texts.standardNotificationLongJobEmail}
      text={formatText(getFigmaText(Texts.standardNotificationLongJobEmail), [vitecNextOrder.useremail])}
    />
  )
}
