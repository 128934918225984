import styled from '@emotion/styled'
import { TextareaAutosize as MaterialTextAreaAutoSize } from '@mui/base'
import { TextFieldProps } from '@mui/material/TextField/TextField'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Colors, { LightGrey } from '../../../../pure/libs/Colors'
import { formatRoutePathVitecNextOrderProduct } from '../../../../pure/libs/RoutePath'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { BorderRadixes } from '../enums/BorderRadixes'
import { bigSpacing, minSpacing, smallSpacing } from '../enums/Spacings'
import { useUpload, useVitecNOOrder } from '../hooks/QueryHooks'
import { useJaguarResults } from '../hooks/useJaguarResults'
import { useUploadedFiles } from '../hooks/useUploadedFiles'
import { updateUpload } from '../libs/DBApiHandler'
import { VITEC_NEXT_ORDER_PRODUCT_FEEDBACK_CONTAINER_WIDTH } from '../libs/HardCodedSizes'
import Images from '../libs/Images'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../libs/Texts'
import { success } from '../libs/ToastHelper'
import { VitecNextOrderRedirectPage } from '../pages/VitecNextOrderPage'
import Box from './Box'
import DiffLines from './DiffLines'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import { ThumbDown, ThumbUp, VitecNextOrderArrowBackIcon } from './Icons'
import { Layout } from './Layout'
import { LoadingPage } from './LoadingPage'
import MenuVitecNext from './MenuVitecNext'
import { NoWrapFigmaText } from './StyledComponents'
import { VitecNextOrderProductButtonsFeedback } from './VitecNextOrderProductButtonsFeedback'
import { VitecNextOrderProductButtonsFulfill } from './VitecNextOrderProductButtonsFulfill'
import { VitecNextOrderProductHeader } from './VitecNextOrderProductHeader'
import { VitecNextProductInfoItems } from './VitecNextProductInfoItems'
import { VitecNextProductSourceFileInfo } from './VitecNextProductSourceFileInfoDeprecated'

export const VitecNextOrderProductFeedbackView: React.FC<VitecNextOrderProductProps> = (props) => {
  const { vitecNextOrderId, vitecNextProduct } = props
  const item = VitecNextProductInfoItems[vitecNextProduct]
  const { enableDiffInFeedbackView = true } = item

  const navigate = useNavigate()
  const { data: vitecNextOrder, isLoading } = useVitecNOOrder(vitecNextOrderId)
  const leopardOrder = vitecNextOrder?.jaguarResponses?.[vitecNextProduct]
  const requestId = leopardOrder?.requestId
  const { data: upload } = useUpload(leopardOrder?.requestId || '')
  const { data: uploadedFiles = [] } = useUploadedFiles(leopardOrder?.requestId || '')

  const { data: jaguarResults } = useJaguarResults(vitecNextOrderId, requestId)

  const jaguarText = item.getText?.({ upload, uploadedFiles, leopardOrder, jaguarResults }) || ''
  const defaultText = upload?.feedbackText || jaguarText
  const [feedbackText, setFeedbackText] = React.useState(defaultText)

  const onClickBack = () => navigate(formatRoutePathVitecNextOrderProduct({ vitecNextOrderId, vitecNextProduct }))

  useEffect(() => setFeedbackText(defaultText), [defaultText])

  if (!item) return <VitecNextOrderRedirectPage vitecNextOrderId={vitecNextOrderId} />

  if (isLoading) return <LoadingPage />
  if (!vitecNextOrder) return <Box />

  const onPreFulfillment = () => upload && updateUpload({ ...upload, feedbackText })

  const onClickThumb = async (feedback: 'good' | 'bad') =>
    upload &&
    updateUpload({ ...upload, feedback: { ...upload.feedback, ui: feedback } }).then(() =>
      success(getFigmaText(Texts.standardFeedbackSent))
    )

  return (
    <Layout enableFooter={false} MenuComponent={MenuVitecNext} enableRightBottomImage={false}>
      <Box fullWidth>
        <Box fullWidth fullPadding direction="row" justify="space-between" spacing={bigSpacing}>
          <Box>
            <Box direction="row" link onClick={onClickBack}>
              <Box>
                <VitecNextOrderArrowBackIcon />
              </Box>
              <Box left spacing={smallSpacing}>
                <NoWrapFigmaText textKey={Texts.standardReturnToDashboard} />
              </Box>
            </Box>
          </Box>
          <Box fullWidth width={`${VITEC_NEXT_ORDER_PRODUCT_FEEDBACK_CONTAINER_WIDTH}px`}>
            <VitecNextOrderProductHeader
              vitecNextOrder={vitecNextOrder}
              headerTextKey={Texts.feedbackFeedbackSectionHeader}
              vitecNextProduct={vitecNextProduct}
              enableExperimentalVersion={false}
            />
            <Box top>
              <FigmaImageContainer imageKey={Images.gazelleInCircle} />
            </Box>
            <Box top fullWidth>
              <Container fullWidth fullPadding>
                <Box fullWidth direction="row" justify="space-between" align="center">
                  <Box fullWidth direction="row" align="center">
                    {item.enableFulfillment && (
                      <VitecNextOrderProductButtonsFulfill
                        vitecNextOrderId={vitecNextOrderId}
                        vitecNextProduct={vitecNextProduct}
                        onPreFulfillment={onPreFulfillment}
                      />
                    )}
                    {!item.enableFulfillment && (
                      <VitecNextOrderProductButtonsFeedback
                        vitecNextOrderId={vitecNextOrderId}
                        vitecNextProduct={vitecNextProduct}
                        feedbackText={feedbackText}
                      />
                    )}
                  </Box>
                </Box>
              </Container>
            </Box>
            <Box top>
              <VitecNextProductSourceFileInfo leopardOrder={leopardOrder} />
            </Box>
            <Box top />
            <Container fullWidth fullPadding>
              <Box direction="row" fullWidth align="center" pointer>
                <Box style={{ opacity: upload?.feedback?.ui === 'bad' ? 0.5 : 1 }} onClick={() => onClickThumb('good')}>
                  <ThumbUp />
                </Box>
                <Box
                  left
                  spacing={smallSpacing}
                  style={{ opacity: upload?.feedback?.ui === 'bad' ? 1 : 0.5 }}
                  onClick={() => onClickThumb('bad')}
                >
                  <ThumbDown />
                </Box>
              </Box>
              <Box direction="row" fullWidth top spacing={smallSpacing}>
                <Box fullWidth fullPadding spacing={minSpacing}>
                  <Box>
                    <FigmaText textKey={Texts.ConstructionCiTextHeader} />
                  </Box>
                  <Box top spacing={smallSpacing} fullWidth>
                    <Textarea value={jaguarText} autoFocus disabled />
                  </Box>
                </Box>
                <Box fullWidth fullPadding spacing={minSpacing}>
                  <Box>
                    <FigmaText styleTextKey={Texts.ConstructionCiTextHeader} textKey={Texts.feedbackBetterTextHeader} />
                  </Box>
                  <Box top spacing={smallSpacing} fullWidth>
                    <Textarea value={feedbackText} autoFocus onChange={(e) => setFeedbackText(e.currentTarget.value)} />
                  </Box>
                </Box>
              </Box>
              {enableDiffInFeedbackView && (
                <Box top fullWidth>
                  <DiffLines base={jaguarText} newText={feedbackText} />
                </Box>
              )}
            </Container>
          </Box>
          <Box />
        </Box>
      </Box>
    </Layout>
  )
}

const Container = styled(Box)`
  background-color: ${Colors.white};
  border-radius: ${BorderRadixes.subtle};
`

export const Textarea = (props: TextFieldProps) => {
  return <TextareaAutoSize autoFocus {...props} />
}

const TextareaAutoSize = styled(MaterialTextAreaAutoSize)({
  border: `1px solid ${LightGrey}`,
  outline: 'none',
  resize: 'none',
  borderRadius: BorderRadixes.regular,
  color: Colors.primary100
}) as any
